body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

a {
  color: white;
  text-decoration: none;
  /* no underline */
}

a:visited {
  text-decoration: none;
  color: white;
}

@font-face {
  font-family: "PFGaramondItalic";
  /*Can be any text*/
  src: local("PFGaramondItalic"),
    url(/static/media/PFGaramondItalic.6a5dbb46.ttf) format("truetype");
}


@font-face {
  font-family: "NewYork";
  /*Can be any text*/
  src: local("NewYork"),
    url(/static/media/NewYork.643a93d7.otf) format("truetype");
}

@font-face {
  font-family: "PFGaramondItalic";
  /*Can be any text*/
  src: local("PFGaramondItalic"),
    url(/static/media/PFGaramondItalic.6a5dbb46.ttf) format("truetype");
}



.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}



.slide-in-left {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}



.tracking-in-expand {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }

  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }

  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}


.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@media screen and (min-width : 688px) {
  /* Styles pour cette Media Queries */


  .container {
    display: grid;
    grid-template-columns: 80px 15fr 80px;
    grid-template-rows: 80px 15fr 80px;
    height: 100vh;
    /*font-family: "Times New Roman", Times, serif;*/
    color: white;
  }

  :root {
    --menu-border-size: 7px;
    --menu-border-color: white;
    --menu-delay-time: 1s;
  }

  .two a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
  }

  .two a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1em;
  }

  .four a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
  }

  .four a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1em;
  }

  .six a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
  }

  .six a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1em;
  }

  .eight a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
  }

  .eight a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1em;
  }

  .one {
    border-right: solid;
    border-bottom: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    -webkit-animation: animation02_content 3s forwards;
            animation: animation02_content 3s forwards;
    -webkit-animation-delay: var(--menu-delay-time);
            animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .two {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    -webkit-animation: animation02_content 3s forwards;
            animation: animation02_content 3s forwards;
    -webkit-animation-delay: var(--menu-delay-time);
            animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  @-webkit-keyframes animation02_content {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes animation02_content {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .two p {
    font-size: 40px;
  }

  .three {
    border-bottom: solid;
    border-left: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    -webkit-animation: animation02_content 3s forwards;
            animation: animation02_content 3s forwards;
    -webkit-animation-delay: var(--menu-delay-time);
            animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .four p {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    font-size: 40px;

  }

  .four {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    -webkit-animation: animation02_content 3s forwards;
            animation: animation02_content 3s forwards;
    -webkit-animation-delay: var(--menu-delay-time);
            animation-delay: var(--menu-delay-time);
    opacity: 0;
  }


  .five {
    z-index: 2;
    overflow: auto;
  }

  .five h1 {
    font-size: 100px;
  }


  .six {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    -webkit-animation: animation02_content 3s forwards;
            animation: animation02_content 3s forwards;
    -webkit-animation-delay: var(--menu-delay-time);
            animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .six p {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    font-size: 40px;
  }

  .seven {
    border-right: solid;
    border-top: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    -webkit-animation: animation02_content 3s forwards;
            animation: animation02_content 3s forwards;
    -webkit-animation-delay: var(--menu-delay-time);
            animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .eight {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    border-top: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    -webkit-animation: animation02_content 3s forwards;
            animation: animation02_content 3s forwards;
    -webkit-animation-delay: var(--menu-delay-time);
            animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .nine {
    border-left: solid;
    border-top: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    -webkit-animation: animation02_content 3s forwards;
            animation: animation02_content 3s forwards;
    -webkit-animation-delay: var(--menu-delay-time);
            animation-delay: var(--menu-delay-time);
    opacity: 0;
  }
}

.squareimg {
  width: 100%;
}

.container-r {
  display: none;
}

.menu-btn {
  display: none;
}

/* smartphone */

@media screen and (max-width: 687px) {

  .container {
    display: none;
  }

  .container-r {
    min-height: 100svh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    color: white;
    margin-left: 2%;
    margin-right: 2%;
  }

  .menu-r a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1.3em;
  }

  .menu-r a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1.3em;
  }

  .menu-r {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .squareimg {
    width: 30%;
  }

  .menu-btn {
    position: fixed;
    width: 180px;
    height: 60px;
    top: 80%;
    overflow: hidden;
    background: transparent;
    border: 2px solid white;
    color: white;
    transition: 0.5s;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 3;
  }

  .menuopen {
    width: 80%;
    height: 80%;
    top: 5%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .9);
    border: 2px solid white;
    color: red;
    font-family: Roboto, sans-serif;
    font-size: 1.8em;
    transition: 0.5s;
    z-index: 3;
  }

}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 78vh;
}

.logo {
    width: 80%;
}
.box-stalker{
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
  background-color: black;
  align-items: center;
  margin-top: 2%;
  font-family: 'Courier New', Courier, monospace;
}
.container-knowledge {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
}

.container-knowledge h1 {
    font-family: "PFGaramondItalic";
    font-size: 6em;
    margin-top: 5%;
    margin-bottom: 0;
}

.container-knowledge p {
    margin: 0;
    font-size: 1.2em;
}

.coding {
    background-color: black;
    opacity: 0.8;
    margin-top: 5%;
    margin-left: 20%;
    margin-right: 20%;
    padding: 1%;
    height: 60vh;
    font-family: 'Courier New', Courier, monospace;
}
.container-project {
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

}

.image {
    width: 320px;
    height: 320px;
}

#mouse-parallax-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    z-index: 3;
}

#mouse-parallax-container h1 {
    background-color: yellow;
}

.p-one {
    transition: 0.5s ease;
}

.p-one:hover {}
.container-believe {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 1%;
  margin-right: 1%;
  height: 100%;
}

.container-believe h1 {
  font-family: "PFGaramondItalic";
  font-size: 7em;
  margin-top: 10%;
  margin-bottom: 0;
}

.container-believe p {
  margin: 0;
  font-size: 1.2em;
}

.box-step{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  border: white 1px;
}

.box-step h4 {
  font-size: 3em;
  border: 0.4rem solid;
  border-radius: 50%;
  padding: 15px 32px;
}

.box-step h4:nth-child(1){
  -webkit-animation: fadesimple ease-out 0.5s forwards;
          animation: fadesimple ease-out 0.5s forwards;
}

.box-step h4:nth-child(2){
  -webkit-animation: fadesimple ease-out 0.8s forwards;
          animation: fadesimple ease-out 0.8s forwards;
}

.box-step h4:nth-child(3){
  -webkit-animation: fadesimple ease-out 1.1s forwards;
          animation: fadesimple ease-out 1.1s forwards;
}

@-webkit-keyframes fadesimple {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadesimple {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    opacity: 1;
  }
}

.baseline{
  margin-top: 5%;
  -webkit-animation: fadesimple ease-out 1.5s forwards;
          animation: fadesimple ease-out 1.5s forwards;

}
.follow-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -3%;
}

.follow-container h3 {
    font-size: 200%;
}

.follow-container h3:nth-child(1) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
}

.follow-container h3:nth-child(2) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
}

.follow-container h3:nth-child(3) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
}

.follow-container h3:nth-child(4) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.4s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.4s both;

}

.follow-container h3:nth-child(5) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;

}


@-webkit-keyframes slidetop {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slidetop {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
.detail{
    margin: 1%;
}

.logo-detail{
  width: 300px;
}

.img {
    width: 80%;
    margin: 1%;
}

.container-detail{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
canvas {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  background-color: rgb(36, 0, 46);
  z-index: -1;
}

/*#FF6666*/

