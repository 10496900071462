@media screen and (min-width : 688px) {
  /* Styles pour cette Media Queries */


  .container {
    display: grid;
    grid-template-columns: 80px 15fr 80px;
    grid-template-rows: 80px 15fr 80px;
    height: 100vh;
    /*font-family: "Times New Roman", Times, serif;*/
    color: white;
  }

  :root {
    --menu-border-size: 7px;
    --menu-border-color: white;
    --menu-delay-time: 1s;
  }

  .two a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
  }

  .two a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1em;
  }

  .four a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
  }

  .four a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1em;
  }

  .six a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
  }

  .six a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1em;
  }

  .eight a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
  }

  .eight a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1em;
  }

  .one {
    border-right: solid;
    border-bottom: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    animation: animation02_content 3s forwards;
    animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .two {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    animation: animation02_content 3s forwards;
    animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  @keyframes animation02_content {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .two p {
    font-size: 40px;
  }

  .three {
    border-bottom: solid;
    border-left: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    animation: animation02_content 3s forwards;
    animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .four p {
    transform: rotate(270deg);
    font-size: 40px;

  }

  .four {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    animation: animation02_content 3s forwards;
    animation-delay: var(--menu-delay-time);
    opacity: 0;
  }


  .five {
    z-index: 2;
    overflow: auto;
  }

  .five h1 {
    font-size: 100px;
  }


  .six {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    animation: animation02_content 3s forwards;
    animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .six p {
    transform: rotate(90deg);
    font-size: 40px;
  }

  .seven {
    border-right: solid;
    border-top: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    animation: animation02_content 3s forwards;
    animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .eight {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    border-top: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    animation: animation02_content 3s forwards;
    animation-delay: var(--menu-delay-time);
    opacity: 0;
  }

  .nine {
    border-left: solid;
    border-top: solid;
    border-color: var(--menu-border-color);
    border-width: var(--menu-border-size);
    animation: animation02_content 3s forwards;
    animation-delay: var(--menu-delay-time);
    opacity: 0;
  }
}

.squareimg {
  width: 100%;
}

.container-r {
  display: none;
}

.menu-btn {
  display: none;
}

/* smartphone */

@media screen and (max-width: 687px) {

  .container {
    display: none;
  }

  .container-r {
    min-height: 100svh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    color: white;
    margin-left: 2%;
    margin-right: 2%;
  }

  .menu-r a:visited {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1.3em;
  }

  .menu-r a {
    text-decoration: none;
    color: white;
    font-family: "PFGaramondItalic";
    font-size: 1.3em;
  }

  .menu-r {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .squareimg {
    width: 30%;
  }

  .menu-btn {
    position: fixed;
    width: 180px;
    height: 60px;
    top: 80%;
    overflow: hidden;
    background: transparent;
    border: 2px solid white;
    color: white;
    transition: 0.5s;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 3;
  }

  .menuopen {
    width: 80%;
    height: 80%;
    top: 5%;
    overflow: auto;
    background-color: rgba(0, 0, 0, .9);
    border: 2px solid white;
    color: red;
    font-family: Roboto, sans-serif;
    font-size: 1.8em;
    transition: 0.5s;
    z-index: 3;
  }

}