.detail{
    margin: 1%;
}

.logo-detail{
  width: 300px;
}

.img {
    width: 80%;
    margin: 1%;
}

.container-detail{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}