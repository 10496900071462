.center {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 78vh;
}

.logo {
    width: 80%;
}