.container-believe {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 1%;
  margin-right: 1%;
  height: 100%;
}

.container-believe h1 {
  font-family: "PFGaramondItalic";
  font-size: 7em;
  margin-top: 10%;
  margin-bottom: 0;
}

.container-believe p {
  margin: 0;
  font-size: 1.2em;
}

.box-step{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  border: white 1px;
}

.box-step h4 {
  font-size: 3em;
  border: 0.4rem solid;
  border-radius: 50%;
  padding: 15px 32px;
}

.box-step h4:nth-child(1){
  animation: fadesimple ease-out 0.5s forwards;
}

.box-step h4:nth-child(2){
  animation: fadesimple ease-out 0.8s forwards;
}

.box-step h4:nth-child(3){
  animation: fadesimple ease-out 1.1s forwards;
}

@keyframes fadesimple {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
  }
}

.baseline{
  margin-top: 5%;
  animation: fadesimple ease-out 1.5s forwards;

}