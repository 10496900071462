.container-knowledge {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
}

.container-knowledge h1 {
    font-family: "PFGaramondItalic";
    font-size: 6em;
    margin-top: 5%;
    margin-bottom: 0;
}

.container-knowledge p {
    margin: 0;
    font-size: 1.2em;
}

.coding {
    background-color: black;
    opacity: 0.8;
    margin-top: 5%;
    margin-left: 20%;
    margin-right: 20%;
    padding: 1%;
    height: 60vh;
    font-family: 'Courier New', Courier, monospace;
}