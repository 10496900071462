.container-project {
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

}

.image {
    width: 320px;
    height: 320px;
}

#mouse-parallax-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    z-index: 3;
}

#mouse-parallax-container h1 {
    background-color: yellow;
}

.p-one {
    transition: 0.5s ease;
}

.p-one:hover {}