.follow-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -3%;
}

.follow-container h3 {
    font-size: 200%;
}

.follow-container h3:nth-child(1) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
}

.follow-container h3:nth-child(2) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
}

.follow-container h3:nth-child(3) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s both;
}

.follow-container h3:nth-child(4) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.4s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.4s both;

}

.follow-container h3:nth-child(5) {
    -webkit-animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
    animation: slidetop 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;

}


@-webkit-keyframes slidetop {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slidetop {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}